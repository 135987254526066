import { ApiResponse, IVatTuWithSoLuongDaXuat, PaginationResponseBase, IBienBanGiaiTheResponseBase } from './../interfaces/ApiResponses';
import { apiServiceInstance } from "@/core/helpers/utils";
import { IPaginationParams } from '../interfaces/Models';
import { useDateTime } from '@/core/hooks/use-date-time-hook';

const { moment } = useDateTime();

export interface INewBienBanParams {
  so_bien_ban: string;
  id_cha?: number;
  ngay_vao_xuong: string;
  id_ke_hoach_sua_chua: number;
  id_toa_xe: number;
  has_dinh_muc_dong: boolean;
  vat_tu_items: IVatTuWithSoLuongDaXuat[];
  id_bien_ban?: number;
  id_cap_sc: number;
}

export interface IBienBanPaginationFilterParams extends IPaginationParams {
  id_ke_hoach_sc?: number;
  searchTerm?: string;
}

export class BienBanGiaiTheService {
  static list({
    current_page,
    per_page,
    id_ke_hoach_sc,
    searchTerm,
  }: IBienBanPaginationFilterParams): Promise<{
    data: ApiResponse<PaginationResponseBase<IBienBanGiaiTheResponseBase>>
  }> {
    return apiServiceInstance().vueInstance.axios.get(
      '/api/v1/bien-ban-giai-the/danh-sach',
      {
        params: {
          page: current_page ? current_page : 1,
          ...(id_ke_hoach_sc && {
            id_ke_hoach_sc,
          }),
          ...(searchTerm && {
            s: searchTerm,
          }),
        },
        headers: {
          perpage: per_page ? per_page : 1000,
        }
      }
    );
  }

  static get(id: number): Promise<{
    data: ApiResponse<IBienBanGiaiTheResponseBase>
  }> {
    return apiServiceInstance().vueInstance.axios.get(
      `/api/v1/bien-ban-giai-the/chi-tiet/${id}`,
    );
  }

  static createOrUpdate({
    so_bien_ban,
    id_cha,
    ngay_vao_xuong,
    id_ke_hoach_sua_chua,
    id_toa_xe,
    has_dinh_muc_dong,
    vat_tu_items,
    id_bien_ban,
    id_cap_sc,
  }: INewBienBanParams): Promise<{
    data: ApiResponse<IBienBanGiaiTheResponseBase>
  }> {
    const isCreating = !id_bien_ban;

    apiServiceInstance().vueInstance.axios.defaults.headers.common[
      "Content-Type"
      ] = "multipart/form-data";
    const bodyFormData = new FormData();

    if (id_cha) {
      bodyFormData.append('bienBanGiaiThe[id_cha]', id_cha.toString());
    }

    bodyFormData.append('bienBanGiaiThe[so_bien_ban]', so_bien_ban);
    bodyFormData.append('bienBanGiaiThe[id_cap_sc]', id_cap_sc.toString());
    bodyFormData.append('bienBanGiaiThe[id_toa_xe]', id_toa_xe.toString());
    bodyFormData.append('bienBanGiaiThe[ngay_vao_xuong]', ngay_vao_xuong);
    bodyFormData.append('bienBanGiaiThe[id_ke_hoach_sua_chua]', id_ke_hoach_sua_chua.toString());
    bodyFormData.append('bienBanGiaiThe[has_dinh_muc_dong]', has_dinh_muc_dong ? '1' : '0');

    if (has_dinh_muc_dong) {
      vat_tu_items.forEach(vatTuItem => {
        bodyFormData.append(`bienBanGiaiThe[dinhMucDongArray][${vatTuItem.id}][so_luong]`, vatTuItem.so_luong.toString());

        if (vatTuItem.ghi_chu) {
          bodyFormData.append(`bienBanGiaiThe[dinhMucDongArray][${vatTuItem.id}][ghi_chu]`, vatTuItem.ghi_chu);
        }
      });
    }

    const URI = isCreating ? '/api/v1/bien-ban-giai-the/tao-moi' : `/api/v1/bien-ban-giai-the/cap-nhat/${id_bien_ban}`;

    return apiServiceInstance().vueInstance.axios.post(
      URI,
      bodyFormData,
    );
  }

  static completeBienBan({
    id_bien_ban,
    ngay_ra_xuong,
  }: {
    id_bien_ban: number;
    ngay_ra_xuong: Date;
  }) {
    const dateStr = moment(ngay_ra_xuong).format('YYYY-MM-DD');

    apiServiceInstance().vueInstance.axios.defaults.headers.common[
        "Content-Type"
        ] = "multipart/form-data";
    const bodyFormData = new FormData();

    bodyFormData.append('data[ngay_ra_xuong]', dateStr);

    return apiServiceInstance().vueInstance.axios.post(
      `/api/v1/bien-ban-giai-the/hoan-thanh/${id_bien_ban}`,
      bodyFormData,
    );
  }
}
