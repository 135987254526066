import { ApiResponse, IPhieuNhapKhoResponseBase, PaginationResponseBase } from './../interfaces/ApiResponses';
import { apiServiceInstance } from '../helpers/utils';
import { IPaginationParams } from '../interfaces/Models';
import { useDateTime } from '@/core/hooks/use-date-time-hook';

const { moment } = useDateTime();

export interface IUpdatePhieuNhapTuHopDongRequest {
  id: number;
  ma_tk_co?: number;
  ma_ct_co?: string;
  ten_tk_co?: string;
  nguoi_giao: string;
  so_hop_dong?: string;
  ngay_hop_dong?: string;
  so_hoa_don?: string;
  ngay_hoa_don: string;
}

export interface ICreateOrUpdatePhieuLeRequest {
  ngay_nhap_kho: string;
  ma_tk_co: string;
  ma_ct_co: string;
  ten_tk_co: string;
  id_kho: string;
  id_phieu_nhap?: number;
  is_updating?: boolean;
  so_hoa_don: string;
  ngay_hoa_don: string;
  nguoi_giao: string;
  vat_tu_items: {
    id: number,
    so_luong: number,
    don_gia: number,
  }[];
}

export interface IUpdateVatTuItemsPhieuLeRequestPayload {
  id: number;
  vat_tu_items: {
    id: number;
    so_luong: number;
    don_gia: number;
    phuong_thuc_kiem_nghiem?: string;
  }[];
}

export interface IPhieuNhapPaginationFilterParams extends IPaginationParams {
  id_kho: number;
  search?: number;
}

export class PhieuNhapKhoService {
  static get(id: number): Promise<{
    data: ApiResponse<IPhieuNhapKhoResponseBase>;
  }> {
    return apiServiceInstance().vueInstance.axios.get(
      `/api/v1/phieu-nhap-kho/chi-tiet/${id}`,
    );
  }

  static update(request: IUpdatePhieuNhapTuHopDongRequest): Promise<{
    data: ApiResponse<IPhieuNhapKhoResponseBase>
  }> {
    apiServiceInstance().vueInstance.axios.defaults.headers.common[
      "Content-Type"
    ] = "multipart/form-data";
    const bodyFormData = new FormData();

    if (request.ma_tk_co) {
      bodyFormData.append("phieuNhapKho[ma_tk_co]", request.ma_tk_co.toString());
    }

    if (request.ma_ct_co) {
      bodyFormData.append("phieuNhapKho[ma_ct_co]", request.ma_ct_co);
    }

    if (request.ten_tk_co) {
      bodyFormData.append("phieuNhapKho[ten_tk_co]", request.ten_tk_co);
    }

    bodyFormData.append("phieuNhapKho[nguoi_giao]", request.nguoi_giao);

    if (request.so_hop_dong) {
      bodyFormData.append("phieuNhapKho[so_hop_dong]", request.so_hop_dong);
    }

    if (request.ngay_hop_dong) {
      bodyFormData.append("phieuNhapKho[ngay_hop_dong]", request.ngay_hop_dong);
    }

    if (request.so_hoa_don) {
      bodyFormData.append("phieuNhapKho[so_hoa_don]", request.so_hoa_don);
    }

    bodyFormData.append("phieuNhapKho[ngay_hoa_don]", request.ngay_hoa_don);

    return apiServiceInstance().vueInstance.axios.post(
      `/api/v1/phieu-nhap-kho/cap-nhat/${request.id}`,
      bodyFormData
    );
  }

  static createOrUpdatePhieuLe(request: ICreateOrUpdatePhieuLeRequest): Promise<{
    data: ApiResponse<IPhieuNhapKhoResponseBase>
  }> {
    apiServiceInstance().vueInstance.axios.defaults.headers.common[
      "Content-Type"
    ] = "multipart/form-data";
    const bodyFormData = new FormData();

    bodyFormData.append('phieuNhapKho[id_hop_dong]', '0');
    bodyFormData.append('phieuNhapKho[ma_tk_co]', request.ma_tk_co);
    bodyFormData.append('phieuNhapKho[ma_ct_co]', request.ma_ct_co);
    bodyFormData.append('phieuNhapKho[ten_tk_co]', request.ten_tk_co);
    bodyFormData.append('phieuNhapKho[id_kho]', request.id_kho);
    bodyFormData.append('phieuNhapKho[so_hop_dong]', '0');
    bodyFormData.append('phieuNhapKho[so_hoa_don]', request.so_hoa_don);
    bodyFormData.append('phieuNhapKho[ngay_hoa_don]', request.ngay_hoa_don);
    bodyFormData.append('phieuNhapKho[ngay_nhap_kho]', request.ngay_nhap_kho);
    bodyFormData.append('phieuNhapKho[nguoi_giao]', request.nguoi_giao);

    request.vat_tu_items.forEach(({ id, don_gia, so_luong }) => {
      bodyFormData.append(`phieuNhapKhoVatTu[${id}][don_gia]`, don_gia.toString());
      bodyFormData.append(`phieuNhapKhoVatTu[${id}][so_luong]`, so_luong.toString());
    });

    let uri = `/api/v1/phieu-nhap-kho/${request.id_kho}/tao-moi`;

    if (request.is_updating && request.id_phieu_nhap) {
      uri = `/api/v1/phieu-nhap-kho/cap-nhat/${request.id_phieu_nhap}`;
    }

    return apiServiceInstance().vueInstance.axios.post(
      uri,
      bodyFormData
    );
  }

  static completePhieuNhapKho({
    id,
    ngay_hoan_thanh,
  }: {
    id: number;
    ngay_hoan_thanh: Date;
  }){
    const dateStr = moment(ngay_hoan_thanh).format('YYYY-MM-DD');

    apiServiceInstance().vueInstance.axios.defaults.headers.common[
      "Content-Type"
    ] = "multipart/form-data";

    const bodyFormData = new FormData();

    bodyFormData.append('extra[ngay_hoan_thanh]', dateStr);

    return apiServiceInstance().vueInstance.axios.post(
      `/api/v1/phieu-nhap-kho/update-ngay-hoan-thanh/${id}`,
      bodyFormData,
    );
  }

  static getListByKhoId({
    current_page,
    per_page,
    id_kho,
    search,
  }: IPhieuNhapPaginationFilterParams): Promise<{
    data: ApiResponse<PaginationResponseBase<IPhieuNhapKhoResponseBase>>
  }> {
    return apiServiceInstance().vueInstance.axios.get(
      `/api/v1/phieu-nhap-kho/danh-sach/kho/${id_kho}`, {
        params: {
          page: current_page ?? 1,
          ...(search && {
            s: search,
          }),
        },
        headers: {
          perpage: per_page ?? 1000,
        }
      }
    );
  }

  static delete(id: number) {
    return apiServiceInstance().vueInstance.axios.post(`/api/v1/phieu-nhap-kho/xoa/${id}`)
  }

  static updateVatTuItemsPhieuNhapLe({
    id: idPhieuNhap,
    vat_tu_items,
  }: IUpdateVatTuItemsPhieuLeRequestPayload) {
    apiServiceInstance().vueInstance.axios.defaults.headers.common[
      "Content-Type"
      ] = "multipart/form-data";

    const bodyFormData = new FormData();

    vat_tu_items.forEach(({ id, so_luong, don_gia, phuong_thuc_kiem_nghiem }) => {
      bodyFormData.append(`vatTuNhapKho[${id}][so_luong]`, so_luong.toString());

      bodyFormData.append(`vatTuNhapKho[${id}][don_gia]`, don_gia.toString());

      if (phuong_thuc_kiem_nghiem) {
        bodyFormData.append(`vatTuNhapKho[${id}][phuong_thuc_kiem_nghiem]`, phuong_thuc_kiem_nghiem);
      }
    });

    return apiServiceInstance().vueInstance.axios.post(
      `/api/v1/phieu-nhap-kho/${idPhieuNhap}/vat-tu`,
      bodyFormData,
    );
  }
}
